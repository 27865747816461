<template>
  <div>
    <div class="position-relative">
      <UIBanner
        :classLoading="'placeholders-banner-game'"
        :keyData="'w_article'"
        :isShowCaption="false"
        :classImage="'img-common customImgCommon'"
        :srcImg="srcImg"
      />
      <div v-if="!srcImg" class="bannerText">{{ title }}</div>
    </div>
    <div v-if="content" class="container py-md-0 py-3">
      <div class="content">
        <div class="fullDescription" v-html="content"></div>
      </div>
    </div>
  </div>
</template>
<script>
import UIBanner from '@/components/UI/Banner/UIBanner';

export default {
  components: {
    UIBanner
  },
  data() {
    return {
      content: '',
      title: '',
      srcImg: ''
    };
  },
  created() {
    this.getArticleBySlug();
  },
  computed: {
    isOnlyMobile() {
      return this.$store.getters.getterIsOnlyMobile;
    }
  },
  methods: {
    getArticleBySlug() {
      if (window.location.href.indexOf('huong-dan-download') >= 0) {
        this.title = "Hướng dẫn download"
        this.content = `
          <style type="text/css">
          .huongdan h3 {
            font-weight: bold;
            font-size: 24px;
            text-align: center;
            color: #1F4F98;
          }

          .huongdan .step {
            color: #1F4F98;
            font-size: 18px;
            font-weight: bold;
          }

          .huongdan .img-fluid {
            max-height: 230px;
            margin-bottom: 1rem;
          }

          .huongdan a {
            text-decoration: underline;
          }
        </style>
        <div class="huongdan">
          <h3 class="mb-4">Tải v&agrave; c&agrave;i đặt ứng dụng Thần Tài tr&ecirc;n Android</h3>
          <div class="row mb-5">
            <div class="col-md-4 text-center">
              <img alt="" class="img-fluid" src="https://assets-vl.s3.ap-southeast-1.amazonaws.com/thantai/huongdan/android-qr_thantai.png" />
              <p class="step">Bước 1</p>
              <p>Sử dụng Zalo qu&eacute;t m&atilde; QR hoặc <a href="https://d1aleksa3dshq6.cloudfront.net/thantai_release.apk" target="_blank">bấm v&agrave;o đ&acirc;y</a> để tải về file ứng dụng </p>
            </div>
            <div class="col-md-4 text-center">
              <img alt="" class="img-fluid" src="https://assets-vl.s3.ap-southeast-1.amazonaws.com/thantai/huongdan/help-android-2.jpg" />
              <p class="step">Bước 2</p>
              <p>Mở file vừa tải xuống v&agrave; nhấn <strong>C&agrave;i đặt</strong>
              </p>
            </div>
            <div class="col-md-4 text-center">
              <img alt="" class="img-fluid" src="https://assets-vl.s3.ap-southeast-1.amazonaws.com/thantai/huongdan/help-android-3.jpg" />
              <p class="step">Bước 3</p>
              <p>Chờ ứng dụng c&agrave;i đặt v&agrave; nhấn <strong>Mở</strong> để bắt đầu sử dụng </p>
            </div>
            <div class="col-md-12">
              <div class="alert alert-warning" role="alert">Lưu &yacute;: Nếu kh&ocirc;ng tải được file, vui l&ograve;ng v&agrave;o mục &quot;C&agrave;i đặt&quot; tr&ecirc;n điện thoại v&agrave; kiểm tra t&iacute;nh năng &quot;Cho ph&eacute;p c&agrave;i đặt ứng dụng kh&ocirc;ng x&aacute;c định&quot;. Xem hướng dẫn <a href="/thong-tin/cach-cai-dat-ung-dung-android">tại đ&acirc;y</a>. </div>
            </div>
          </div>
          <h3 class="mb-4">Tải v&agrave; c&agrave;i đặt ứng dụng Thần Tài tr&ecirc;n iOS</h3>

        <div class="row">
            <div class="col-12 text-center">
            Đang cập nhật
            </div>
        </div>
          
          <div class="row" style="display: none;">
            <div class="col-md-4 text-center">
              <img alt="" class="img-fluid" src="https://assets-vl.s3.ap-southeast-1.amazonaws.com/test/help-ios-1_1638009620127.jpg" />
              <p class="step">Bước 1</p>
              <p>Sử dụng Zalo qu&eacute;t m&atilde; QR hoặc <a href="https://apps.apple.com/vn/app/t%C3%A0i-l%E1%BB%99c-mua-h%E1%BB%99-vietlott/id1536375131?l=vi" target="_blank">bấm v&agrave;o đ&acirc;y</a> để đến trang tải ứng dụng tr&ecirc;n Apple Store </p>
            </div>
            <div class="col-md-4 text-center">
              <img alt="" class="img-fluid" src="https://assets-vl.s3.ap-southeast-1.amazonaws.com/thantai/huongdan/help-ios-2.jpg" />
              <p class="step">Bước 2</p>
              <p>Nhấn n&uacute;t <strong>NHẬN</strong> để bắt đầu tải về </p>
            </div>
            <div class="col-md-4 text-center">
              <img alt="" class="img-fluid" src="https://assets-vl.s3.ap-southeast-1.amazonaws.com/thantai/huongdan/help-ios-3.jpg" />
              <p class="step">Bước 3</p>
              <p>Sau khi ứng dụng c&agrave;i đặt xong, nhấn mở ứng dụng Thần Tài để bắt đầu sử dụng</p>
            </div>
          </div>
        </div>
        `;
      } else {
        let slug = this.$route.path.split('/')[2];
        this.$store
          .dispatch('getArticleBySlug', slug)
          .then((resp) => {
            const { content, title, image_url } = resp;
            this.$emit('updateTitleMobile', title);
            this.title = title;
            if (title.toUpperCase() === 'GIỚI THIỆU') {
              this.content = `
              <p>Ứng dụng Thần Tài là ứng dụng mua hộ vé số kiến thiết giúp khách hàng có thể mua vé số kiến thiết từ bất kỳ nơi nào.</p>
              <p>
                <strong>*** Cơ hội trên tay - Bắt ngay tiền tỷ ***</strong>
              </p>
              <p>Cơ hội qua đi sẽ không bao giờ trở lại, đừng để bất kỳ lý do gì cản trở cơ hội đổi đời của bản thân. Hãy để ứng dụng Thần Tài giúp bạn. <br>Chỉ ngồi tại chỗ, Thần Tài sẽ giúp bạn lựa chọn vé số một cách nhanh chóng, tiện lợi và dễ dàng nhất.</p>
              <p>
                <strong>T&iacute;nh năng nổi bật:</strong>
              </p>
              <ul>
                <li>Dễ dàng chọn vé - Nhanh chóng, đơn giản - Mọi lúc, mọi nơi</li>
                <li>Bảo mật thông tin khách hàng</li>
                <li>Thanh toán online - Tiện lợi, dễ dàng</li>
                <li>Khách hàng sẽ nhận được hình ảnh vé mua</li>
                <li>Khách hàng có thể lựa chọn Thần Tài giữ hộ</li>
              </ul>
              <p>Ngoài ra, ứng dụng Thần Tài còn cung cấp những tính năng cơ bản khác như:</p>
              <ul>
                <li>Cập nhật kết quả mở thưởng liên tục từng kỳ.</li>
                <li>Tra cứu vé một cách nhanh chóng.</li>
              </ul>
              <p>
              Thông tin liên hệ:<br>
              CÔNG TY CỔ PHẦN GIẢI TRÍ SỐ WELUCK<br>
              Địa chỉ: 104 Mai Thị Lựu , Phường Đa Kao, Quận 1, Thành phố Hồ Chí Minh<br>
              E-mail: cskh@thantai68.net<br>
              Giấy phép kinh doanh số: 0317046356<br>
              Cấp ngày: 23/11/2021 bởi Sở Kế Hoạch và Đầu Tư TP. HCM<br>
              </p>
            `;
            } else if (title.toUpperCase() === 'ĐIỀU KHOẢN SỬ DỤNG') {
               this.content = `
                <p>Người d&ugrave;ng vui l&ograve;ng đọc kỹ Điều khoản sử dụng (&quot;Điều khoản&quot;) trước khi tiến h&agrave;nh sử dụng dịch vụ của &ldquo;Thantai68.net&rdquo;. Người d&ugrave;ng chấp thuận v&agrave; đồng &yacute; bị r&agrave;ng buộc bởi c&aacute;c quy định v&agrave; điều kiện trong Điều khoản n&agrave;y khi thực hiện c&aacute;c thao t&aacute;c sử dụng dịch vụ. Trường hợp Người d&ugrave;ng kh&ocirc;ng đồng &yacute; với bất kỳ điều khoản sử dụng n&agrave;o của &ldquo;Thần T&agrave;i 68&rdquo; (phi&ecirc;n bản n&agrave;y v&agrave; c&aacute;c phi&ecirc;n bản cập nhật) vui l&ograve;ng tho&aacute;t khỏi ứng dụng v&agrave; ngưng sử dụng dịch vụ.&nbsp;</p>

                <p><strong>Điều 1. Giới thiệu về dịch vụ</strong></p>

                <p>1.1. &ldquo;Thần T&agrave;i 68&rdquo; l&agrave; Dịch vụ mua hộ v&eacute; số qua Internet của C&ocirc;ng Ty Cổ Phần Giải Tr&iacute; Số WeLuck, l&agrave; dịch vụ do C&ocirc;ng Ty Cổ Phần Giải Tr&iacute; Số WeLuck độc lập ph&aacute;t triển cho ph&eacute;p kh&aacute;ch h&agrave;ng thực hiện c&aacute;c giao dịch nhờ mua hộ v&eacute; xổ số hiện đang được ph&acirc;n phối tr&ecirc;n thị trường một c&aacute;ch hợp ph&aacute;p.</p>

                <p>1.2. Người d&ugrave;ng c&oacute; thể lựa chọn c&aacute;c v&eacute; xổ số (v&eacute; xổ số điện to&aacute;n thủ đ&ocirc;, c&aacute;c v&eacute; xổ số kiến thiết&hellip;) theo &yacute; muốn của m&igrave;nh th&ocirc;ng qua dịch vụ mua hộ v&eacute; số của &ldquo;Thần T&agrave;i 68&rdquo;, sau khi Người d&ugrave;ng x&aacute;c nhận, &ldquo;Thần T&agrave;i 68&rdquo; sẽ chuyển th&ocirc;ng tin đơn h&agrave;ng tới c&aacute;c điểm b&aacute;n l&agrave; Đại l&yacute; của c&aacute;c C&ocirc;ng Ty Xổ Số Kiến Thiết, điểm b&aacute;n sẽ b&agrave;n giao chiếc v&eacute; của Người d&ugrave;ng cho &ldquo;Thần T&agrave;i 68&rdquo; bảo quản, lưu trữ.</p>

                <p>1.3. V&eacute; số m&agrave; người d&ugrave;ng lựa chọn đặt mua sẽ được &ldquo;Thần T&agrave;i 68&rdquo; lưu giữ v&agrave; bảo quản, v&eacute; đ&atilde; mua được chụp h&igrave;nh gửi tới Người d&ugrave;ng để Người d&ugrave;ng c&oacute; thể kiểm tra, x&aacute;c thực t&iacute;nh ch&iacute;nh x&aacute;c của chiếc v&eacute; (chi tiết theo Điều 5 Điều khoản n&agrave;y).</p>

                <p>1.4. Trường hợp v&eacute; số m&agrave; Người d&ugrave;ng lựa chọn tr&uacute;ng thưởng, Thần T&agrave;i 68 sẽ th&ocirc;ng b&aacute;o tới người d&ugrave;ng ngay sau khi c&oacute; kết quả quay số mở thưởng từ c&aacute;c C&ocirc;ng Ty Xổ Số Kiến Thiết. Ngay sau đ&oacute;, Thần T&agrave;i 68 sẽ kết hợp c&ugrave;ng Đại l&yacute; v&eacute; số trao thưởng cho kh&aacute;ch h&agrave;ng trong thời gian quy định của c&aacute;c C&ocirc;ng Ty Xổ Số Kiến Thiết.</p>

                <p>1.5. &ldquo;Thần T&agrave;i 68&rdquo; cung cấp những số liệu thống k&ecirc; gi&uacute;p Người d&ugrave;ng c&oacute; th&ecirc;m cơ sở để lựa chọn c&aacute;c bộ số của m&igrave;nh.</p>

                <p>1.6. &ldquo;Thantai68.net&rdquo; cung c&acirc;́p dịch vụ mua&nbsp;hộ v&eacute; số. Dịch vụ của &ldquo;Thần T&agrave;i 68&rdquo; là nhằm k&ecirc;́t n&ocirc;́i Người dùng (Người có nhu c&acirc;̀u mua v&eacute; số), với Người cung c&acirc;́p (c&aacute;c C&ocirc;ng ty Xổ Số, Đại l&yacute; Xổ Số, c&aacute;c Đơn vị, C&aacute; nh&acirc;n được ph&eacute;p kinh doanh Xổ Số theo quy định của Ph&aacute;p luật). &ldquo;Thần T&agrave;i 68&rdquo; kh&ocirc;ng dự định trở th&agrave;nh Người cung cấp v&eacute; số hay thực hi&ecirc;̣n các hoạt đ&ocirc;̣ng nào có th&ecirc;̉ được xem là hoạt đ&ocirc;̣ng của Người cung c&acirc;́p v&eacute; số. Vi&ecirc;̣c cung c&acirc;́p vé số cho Người dùng là tùy thu&ocirc;̣c vào quy&ecirc;́t định của Người cung c&acirc;́p và vi&ecirc;̣c sử dụng dịch vụ đó là thu&ocirc;̣c quy&ecirc;̀n quy&ecirc;́t định của Người dùng.</p>

                <p><strong>Điều 2. Quy định chung</strong></p>

                <p>Người d&ugrave;ng khi sử dụng dịch vụ mua hộ v&eacute; số của &ldquo;Thần T&agrave;i 68&rdquo; phải đ&aacute;p ứng đầy&nbsp;đủ c&aacute;c điều kiện quy định sau:</p>

                <p>2.1. L&agrave; c&ocirc;ng d&acirc;n Việt Nam, người Việt Nam định cư ở nước ngo&agrave;i hoặc người nước ngo&agrave;i nhập cảnh hợp ph&aacute;p v&agrave;o Việt Nam;</p>

                <p>2.2. L&agrave; người từ đủ 18 tuổi trở l&ecirc;n;</p>

                <p>2.3. Kh&ocirc;ng phải l&agrave; người bị hạn chế về năng lực h&agrave;nh vi d&acirc;n sự hoặc mất năng lực h&agrave;nh vi d&acirc;n sự theo quy định của ph&aacute;p luật.</p>

                <p><strong>Điều 3. Quy tr&igrave;nh mua v&eacute;</strong></p>

                <p>Người d&ugrave;ng khi sử dụng dịch vụ mua hộ v&eacute; số của &ldquo;Thần T&agrave;i 68&rdquo; để tham gia dự thưởng sẽ tu&acirc;n thủ đầy đủ theo quy tr&igrave;nh mua v&eacute; của &ldquo;Thần T&agrave;i 68&rdquo; như sau:</p>

                <p>3.1. Quy tr&igrave;nh mua v&eacute;:</p>

                <p>3.1.1. Người d&ugrave;ng lựa chọn v&eacute; mua qua &ldquo;Thần T&agrave;i 68&rdquo;, lựa chọn c&aacute;c th&ocirc;ng tin về v&eacute; mua như:</p>

                <p>3.1.1.1. Loại v&eacute; chơi (Xổ số kiến thiết, Xổ số điện to&aacute;n thủ đ&ocirc;.....).</p>

                <p>3.1.1.2. Loại h&igrave;nh chơi (loto 2 số, loto 3 số, điện to&aacute;n 6x36......)</p>

                <p>3.1.1.3. Kỳ quay số mở thưởng.</p>

                <p>3.1.1.4. Gi&aacute; tiền .</p>

                <p>3.1.2. Sau khi lựa chọn xong v&eacute; cần mua, Người d&ugrave;ng cung cấp th&ocirc;ng tin c&aacute; nh&acirc;n đảm bảo t&iacute;nh sở hữu với v&eacute; mua của m&igrave;nh (Họ v&agrave; t&ecirc;n đầy đủ, số điện thoại, số CMND/</p>

                <p>CCCD) v&agrave; thực hiện thanh to&aacute;n cho những chiếc v&eacute; m&igrave;nh đ&atilde; chọn.</p>

                <p>3.2. Quy tr&igrave;nh mua v&eacute; được x&aacute;c nhận l&agrave; th&agrave;nh c&ocirc;ng khi v&agrave; chỉ khi Người d&ugrave;ng đ&atilde; nhận được h&igrave;nh ảnh của v&eacute; v&agrave; kh&ocirc;ng c&oacute; phản hồi &ldquo;B&aacute;o lỗi v&eacute;&rdquo; như quy định tại Điều 5: Quy định về in v&eacute;, ghi th&ocirc;ng tin v&agrave; gửi h&igrave;nh ảnh của v&eacute; của Điều khoản ch&iacute;nh s&aacute;ch n&agrave;y.</p>

                <p>3.3. Đối với những đơn h&agrave;ng kh&ocirc;ng th&agrave;nh c&ocirc;ng, &ldquo;Thần T&agrave;i 68&rdquo; sẽ c&oacute; nghĩa vụ v&agrave; tr&aacute;ch nhiệm thực hiện ho&agrave;n tiền (chậm nhất sau 05 ng&agrave;y l&agrave;m việc) theo gi&aacute; trị đơn h&agrave;ng cho Người d&ugrave;ng.</p>

                <p>3.4. C&aacute;c trạng th&aacute;i đơn h&agrave;ng ghi nhận tr&ecirc;n hệ thống:</p>

                <p>3.4.1. Đang chờ: Y&ecirc;u cầu đặt v&eacute; đ&atilde; được&nbsp;</p>

                <p>hệ thống đ&atilde; ghi nhận v&agrave; gửi đến Điểm b&aacute;n h&agrave;ng ph&ugrave; hợp nhất để xử l&yacute; in v&eacute;. Ở trạng th&aacute;i n&agrave;y đơn h&agrave;ng của người d&ugrave;ng được t&iacute;nh l&agrave; chưa xử l&yacute; th&agrave;nh c&ocirc;ng.</p>

                <p>3.4.2. Đã mua: Y&ecirc;u cầu đặt v&eacute; đ&atilde; được xử l&yacute; th&agrave;nh c&ocirc;ng v&agrave; Kh&aacute;ch h&agrave;ng nhận được h&igrave;nh ảnh v&eacute; đ&atilde; in tại mục Lịch sử mua v&eacute;. Trường hợp Kh&aacute;ch h&agrave;ng c&oacute; nhu cầu nhận v&eacute; qua email, Kh&aacute;ch h&agrave;ng cập nhật th&ocirc;ng tin email tại bước Đặt v&eacute;.</p>

                <p>3.4.3. Đã hủy: Y&ecirc;u cầu đặt v&eacute; của kh&aacute;ch h&agrave;ng gặp phải một số vấn đề lỗi dẫn đến kh&ocirc;ng được ghi nhận hoặc ghi nhận kh&ocirc;ng th&agrave;nh c&ocirc;ng tr&ecirc;n hệ thống &ldquo;Thần T&agrave;i 68&rdquo;. Đối với c&aacute;c trường hợp n&agrave;y, kh&aacute;ch h&agrave;ng sẽ được &ldquo;Thần T&agrave;i 68&rdquo; ho&agrave;n lại tiền (chậm nhất sau 05 ng&agrave;y l&agrave;m việc).</p>

                <p>3.5. Trong suốt qu&aacute; tr&igrave;nh mua v&eacute; v&agrave; nhận&nbsp;</p>

                <p>thưởng, Người d&ugrave;ng phải tu&acirc;n thủ đầy đủ c&aacute;c Quy định về thanh to&aacute;n, in v&eacute;, ghi th&ocirc;ng tin, gửi h&igrave;nh ảnh v&eacute; v&agrave; c&aacute;c Quy định trả thưởng dưới đ&acirc;y.</p>

                <p><strong>Điều 4. Quy định về thanh to&aacute;n</strong></p>

                <p>4.1. Người d&ugrave;ng sẽ sử dụng trực ti&ecirc;́p số tiền trong Ví đi&ecirc;̣n tử để thanh to&aacute;n cho đơn h&agrave;ng.</p>

                <p>4.2. Người d&ugrave;ng thanh to&aacute;n gi&aacute; trị đơn h&agrave;ng, bao gồm:&nbsp;</p>

                <p>4.2.1. Gi&aacute; trị của c&aacute;c v&eacute; xổ số (được b&aacute;n đ&uacute;ng với gi&aacute; ni&ecirc;m yết theo quy định của Bộ t&agrave;i ch&iacute;nh);</p>

                <p>4.2.2. Ph&iacute; chuyển tiền, ph&iacute; giữ v&eacute; hoặc (v&agrave;) ph&iacute; dịch vụ của V&iacute; điện tử;</p>

                <p>4.2.3. C&aacute;c chi ph&iacute; kh&aacute;c (nếu c&oacute;) theo quy định của &ldquo;Thần T&agrave;i 68&rdquo;.</p>

                <p>4.3. Đơn h&agrave;ng sau khi thanh to&aacute;n v&agrave; được x&aacute;c nhận l&agrave; xử l&yacute; th&agrave;nh c&ocirc;ng khi v&agrave; chỉ khi: Kh&aacute;ch h&agrave;ng nhận được h&igrave;nh ảnh của v&eacute; c&oacute; đầy đủ th&ocirc;ng tin theo quy định tại Điều 5 của Điều khoản n&agrave;y.</p>

                <p><strong>Điều 5. Quy định về in v&eacute;, ghi th&ocirc;ng tin v&agrave; gửi h&igrave;nh ảnh v&eacute;</strong></p>

                <p>5.1. To&agrave;n bộ tất cả c&aacute;c v&eacute; tham gia dự thưởng sau khi được in ra sẽ được ghi M&atilde; đơn h&agrave;ng l&ecirc;n mặt sau của v&eacute; v&agrave; gửi h&igrave;nh ảnh chi tiết mặt trước của chiếc v&eacute; đến người d&ugrave;ng trong phần lịch sử đặt mua v&agrave;/hoặc Email m&agrave; Người d&ugrave;ng đ&atilde; đăng k&yacute;.</p>

                <p>5.2. Sau khi nhận được h&igrave;nh ảnh chiếc v&eacute; vật l&yacute;, Người d&ugrave;ng cần kiểm tra kỹ lưỡng c&aacute;c th&ocirc;ng tin tr&ecirc;n v&eacute;, trong trường hợp ph&aacute;t hiện sai s&oacute;t cần sử dụng chức năng B&aacute;o lỗi v&eacute; (trước khi quay thưởng) để &ldquo;Thần T&agrave;i 68&rdquo; xử l&yacute;. Nếu kh&ocirc;ng c&oacute; sự phản hồi n&agrave;o của Người d&ugrave;ng đến &ldquo;Thần T&agrave;i 68&rdquo; th&igrave; mặc định c&aacute;c th&ocirc;ng tin tr&ecirc;n v&eacute; vật l&yacute; được x&aacute;c nhận l&agrave; ch&iacute;nh x&aacute;c, v&agrave; l&agrave; sự lựa chọn cuối c&ugrave;ng của Người d&ugrave;ng.</p>

                <p>5.3. Chiếc v&eacute; vật l&yacute; được in ra, ghi th&ocirc;ng tin, chụp ảnh gửi Người d&ugrave;ng v&agrave; được x&aacute;c nhận l&agrave; ch&iacute;nh x&aacute;c về mặt th&ocirc;ng tin l&agrave; chứng từ duy nhất c&oacute; gi&aacute; trị ph&aacute;p l&yacute; để Người d&ugrave;ng so thưởng v&agrave; nhận thưởng.&nbsp;</p>

                <p><strong>Điều 6. Quy định về ho&agrave;n tiền v&agrave; hủy v&eacute;</strong></p>

                <p>Trong c&aacute;c trường hợp sau, v&eacute; do Người&nbsp;d&ugrave;ng đặt mua sẽ được hủy v&agrave; ho&agrave;n tiền (Chậm nhất sau 5 ng&agrave;y l&agrave;m việc):</p>

                <p>6.1. Bộ số m&agrave; Người d&ugrave;ng đặt mua đ&atilde; hết ( Điện to&aacute;n 123, Loto 5 số...).</p>

                <p>6.2. V&eacute; đ&atilde; in, ghi th&ocirc;ng tin v&agrave; chụp ảnh gửi đến Người d&ugrave;ng nhận được phản hồi từ Người d&ugrave;ng c&oacute; sự sai s&oacute;t th&ocirc;ng tin.</p>

                <p>6.3. V&eacute; m&agrave; Người d&ugrave;ng đặt mua v&igrave; những l&yacute; do bất khả kh&aacute;ng (v&iacute; dụ: Hệ thống gặp sự cố về đường truyền) m&agrave; &ldquo;Thần T&agrave;i 68&rdquo; kh&ocirc;ng kịp ghi th&ocirc;ng tin v&agrave; chụp ảnh gửi đến Người d&ugrave;ng.</p>

                <p><strong>Điều 7. Quy định về trả thưởng</strong></p>

                <p>7.1. Hệ thống của &ldquo;Thần T&agrave;i 68&rdquo; sẽ tự động cập nhật kết quả quay số mở thưởng&nbsp;của c&aacute;c C&ocirc;ng Ty Xổ Số Kiến Thiết để t&iacute;nh thưởng cho Kh&aacute;ch h&agrave;ng, trong trường hợp hệ thống cập nhật sai hoặc nhầm lẫn th&igrave; kết quả quay số mở thưởng được c&ocirc;ng bố tr&ecirc;n website ch&iacute;nh thức của c&aacute;c C&ocirc;ng Ty Xổ Số Kiến Thiết sẽ l&agrave; căn cứ để t&iacute;nh thưởng. &ldquo;Thần T&agrave;i 68&rdquo; c&oacute; tr&aacute;ch nhiệm trả thưởng bổ sung v&agrave;/hoặc c&oacute; quyền thu hồi lại số tiền đ&atilde; chi thưởng sai cho Kh&aacute;ch h&agrave;ng sau khi cập nhật lại kết quả đ&uacute;ng.</p>

                <p>7.2. Để thuận tiện cho Người d&ugrave;ng, &ldquo;Thần T&agrave;i 68&rdquo; sẽ chủ động tạm ứng tiền tr&uacute;ng thưởng trước cho Người d&ugrave;ng, c&aacute;c v&eacute; tr&uacute;ng thưởng sau đ&oacute; do &ldquo;Thần T&agrave;i 68&rdquo; nộp lại cho c&aacute;c C&ocirc;ng Ty Xổ Số Kiến Thiết để ho&agrave;n thiện thủ tục l&atilde;nh thưởng.</p>

                <p>7.3. Nếu sau khi trừ thuế m&agrave; số tiền thực lĩnh dưới 10,000,000 VNĐ th&igrave; to&agrave;n bộ tiền thưởng sẽ được chuyển một lần v&agrave;o T&agrave;i&nbsp;khoản v&iacute; điện tử (dựa tr&ecirc;n số điện thoại người d&ugrave;ng để lại khi đặt v&eacute;) của Người d&ugrave;ng hoặc được chia nhỏ th&agrave;nh nhiều lần chuyển (T&ugrave;y thuộc v&agrave;o qui định về hạn mức nhận tiền/ ng&agrave;y của từng v&iacute; điện tử. Mức ph&iacute; chuyển tiền v&agrave;o v&iacute; điện tử theo quy định của từng v&iacute; điện tử m&agrave; Người d&ugrave;ng đang sử dụng.</p>

                <p>7.4. Nếu sau khi trừ thuế m&agrave; số tiền thực lĩnh từ tr&ecirc;n hoặc bằng 10,000,000 VNĐ th&igrave; to&agrave;n bộ tiền thưởng sẽ được chuyển một lần v&agrave;o T&agrave;i khoản Ng&acirc;n h&agrave;ng của Người d&ugrave;ng. L&uacute;c n&agrave;y Người d&ugrave;ng cần cung cấp cho &ldquo;Thần T&agrave;i 68&rdquo; th&ocirc;ng tin CMND/CCCD/Hộ chiếu, Hộ khẩu, số t&agrave;i khoản ng&acirc;n h&agrave;ng để l&agrave;m thủ tục nhận thưởng.&nbsp;</p>

                <p>7.5. Mức ph&iacute; chuyển tiền v&agrave;o t&agrave;i khoản ng&acirc;n h&agrave;ng của Người d&ugrave;ng l&agrave; 2% tr&ecirc;n tổng số tiền chuyển khoản</p>

                <p>7.6. Thời gian nhận thưởng: kh&ocirc;ng qu&aacute; 07 ng&agrave;y l&agrave;m việc kể từ khi &ldquo;Thần T&agrave;i 68&rdquo; nhận được y&ecirc;u cầu v&agrave; th&ocirc;ng tin chuyển tiền của Người d&ugrave;ng (đối với trường hợp Người d&ugrave;ng tr&uacute;ng thưởng từ tr&ecirc;n hoặc bằng 10 triệu th&igrave; thời gian bắt đầu được t&iacute;nh khi nhận được đầy đủ c&aacute;c giấy tờ x&aacute;c minh của Người d&ugrave;ng).</p>

                <p>7.7. Do v&eacute; của Người d&ugrave;ng được &ldquo;Thần T&agrave;i 68&rdquo; bảo quản v&agrave; giữ hộ n&ecirc;n để đảm bảo quyền lợi cho &ldquo;Thần T&agrave;i 68&rdquo; v&agrave; Người d&ugrave;ng, &ldquo;Thần T&agrave;i 68&rdquo; quyết định kh&ocirc;ng c&ocirc;ng bố to&agrave;n vẹn m&atilde; vạch v&agrave; serial của v&eacute; số m&agrave; che đi một phần m&atilde; vạch, serial của v&eacute; số khi chụp ảnh gửi lại cho Người d&ugrave;ng.</p>

                <p><strong>Điều 8. Giới hạn tr&aacute;ch nhiệm</strong></p>

                <p>8.1. Người d&ugrave;ng đồng &yacute; bảo vệ, bồi thường v&agrave; giữ cho &ldquo;Thần T&agrave;i 68&rdquo; v&agrave; c&aacute;c c&ocirc;ng ty li&ecirc;n kết của &ldquo;Thần T&agrave;i 68&rdquo; kh&ocirc;ng bị tổn hại bởi bất kỳ khiếu nại, chi ph&iacute;, ph&aacute;n quyết của to&agrave; &aacute;n, bao gồm nhưng kh&ocirc;ng giới hạn ph&iacute; luật sư, li&ecirc;n quan đến hoặc ph&aacute;t sinh từ việc vi phạm điều khoản sử dụng hoặc bất kỳ sự sử dụng của Người d&ugrave;ng dưới bất kỳ h&igrave;nh thức n&agrave;o.</p>

                <p>8.2. Người dùng đ&ocirc;̀ng ý cho phép &ldquo;Thần T&agrave;i 68&rdquo; sử dụng dữ li&ecirc;̣u cá nh&acirc;n của mình (Bao g&ocirc;̀m Họ t&ecirc;n, số điện thoại...) và chuy&ecirc;̉n giao những dữ li&ecirc;̣u này đ&ecirc;́n Người cung c&acirc;́p phục vụ cho mục đích cung c&acirc;́p dịch vụ. Người dùng đ&ocirc;̀ng thời cũng cho phép &ldquo;Thần T&agrave;i 68&rdquo; được tự do chu&acirc;̉n bị, thu th&acirc;̣p, lưu trữ và c&acirc;̣p nh&acirc;̣t dữ li&ecirc;̣u cá nh&acirc;n trong phạm vi và thời gian c&acirc;̀n thi&ecirc;́t trong quá trình &ldquo;Thần T&agrave;i 68&rdquo; cung c&acirc;́p dịch vụ ứng dụng.</p>

                <p>8.3. Người dùng đ&ocirc;̀ng ý và ch&acirc;́p nh&acirc;̣n rằng vi&ecirc;̣c chu&acirc;̉n bị, thu th&acirc;̣p, lưu trữ, sử dụng và truy&ecirc;̀n tải dữ li&ecirc;̣u cá nh&acirc;n của mình của &ldquo;Thần T&agrave;i 68&rdquo; là tu&acirc;n theo Chính sách Bảo m&acirc;̣t và Thu th&acirc;̣p Dữ li&ecirc;̣u Cá Nh&acirc;n (Chính sách Bảo m&acirc;̣t) được c&ocirc;ng b&ocirc;́ và c&acirc;̣p nh&acirc;̣t định kì tại Điều khoản sử dụng n&agrave;y. Người dùng cũng ch&acirc;́p nh&acirc;̣n đọc và hi&ecirc;̉u rõ Chính sách Bảo m&acirc;̣t trước khi đ&ocirc;̀ng ý với các Đi&ecirc;̀u ki&ecirc;̣n sử dụng.</p>

                <p>8.4. &ldquo;Thần T&agrave;i 68&rdquo; có th&ecirc;̉ đi&ecirc;̀u chỉnh các Đi&ecirc;̀u ki&ecirc;̣n sử dụng nói tr&ecirc;n tùy theo thời gian mà kh&ocirc;ng c&acirc;̀n th&ocirc;ng báo trước.</p>

                <p>8.5. Trong vi&ecirc;̣c cung c&acirc;́p dịch vụ mua hộ v&eacute; số của &ldquo;Thần T&agrave;i 68&rdquo;, &ldquo;Thần T&agrave;in 68&rdquo; kh&ocirc;ng hoạt đ&ocirc;̣ng như m&ocirc;̣t đại lý hoặc đại di&ecirc;̣n cho Người dùng hay Người cung c&acirc;́p.</p>

                <p>8.6. Người dùng ch&acirc;́p nh&acirc;̣n m&ocirc;̣t cách đ&acirc;̀y đủ và v&ocirc; đi&ecirc;̀u ki&ecirc;̣n n&ocirc;̣i dung hi&ecirc;̣n hành của các Đi&ecirc;̀u ki&ecirc;̣n sử dụng cho từng l&acirc;̀n sử dụng dịch vụ.</p>

                <p><strong>Điều 9. Ch&iacute;nh s&aacute;ch bảo mật</strong></p>

                <p>Dịch vụ mua hộ v&eacute; số của &ldquo;Thần T&agrave;i 68&rdquo; v&agrave; trang web c&ocirc;ng ty (gồm cả nền tảng website, mobile website), sau đ&acirc;y gọi chung l&agrave; &ldquo;&ldquo;Thần T&agrave;i 68&rdquo;&rdquo;, cam kết sẽ bảo mật những th&ocirc;ng tin mang t&iacute;nh ri&ecirc;ng tư của Người d&ugrave;ng. Qu&yacute; kh&aacute;ch vui l&ograve;ng đọc &ldquo;Ch&iacute;nh s&aacute;ch bảo mật&rdquo; dưới đ&acirc;y trước khi truy cập những nội dung kh&aacute;c để hiểu hơn những cam kết m&agrave; &ldquo;Thần T&agrave;i 68&rdquo; thực hiện, nhằm t&ocirc;n trọng v&agrave; bảo vệ quyền lợi của người truy cập:</p>

                <p>9.1. Thu thập th&ocirc;ng tin c&aacute; nh&acirc;n:</p>

                <p>9.1.1. &ldquo;Thần T&agrave;i 68&rdquo; thực hiện việc thu thập c&aacute;c th&ocirc;ng tin c&aacute; nh&acirc;n m&agrave; Qu&yacute; kh&aacute;ch cung cấp trực tiếp cho &ldquo;Thần T&agrave;i 68&rdquo; bao gồm:</p>

                <p>a. Họ v&agrave; t&ecirc;n</p>

                <p>b. Số Chứng minh nh&acirc;n d&acirc;n/Căn cước c&ocirc;ng d&acirc;n</p>

                <p>c. Số điện thoại</p>

                <p>d. Địa chỉ email (nếu c&oacute;)</p>

                <p>e. Địa chỉ (nếu c&oacute;)</p>

                <p>9.1.2. C&aacute;c th&ocirc;ng tin thu thập sẽ gi&uacute;p &ldquo;Thần T&agrave;i 68&rdquo;:</p>

                <p>a. Hỗ trợ Người d&ugrave;ng khi mua v&eacute;</p>

                <p>b. Giải đ&aacute;p thắc mắc Người d&ugrave;ng.</p>

                <p>c. Cung cấp cho qu&yacute; kh&aacute;ch th&ocirc;ng tin mới nhất của &ldquo;Thần T&agrave;i 68&rdquo;.</p>

                <p>d. Xem x&eacute;t v&agrave; n&acirc;ng cấp nội dung v&agrave; giao diện của website v&agrave; dịch vụ.</p>

                <p>9.1.3. Thực hiện c&aacute;c hoạt động quảng b&aacute; li&ecirc;n quan đến c&aacute;c sản phẩm v&agrave; dịch vụ của &ldquo;Thần T&agrave;i 68&rdquo;</p>

                <p>9.1.4. &ldquo;Thần T&agrave;i 68&rdquo; cũng c&oacute; thể thu thập th&ocirc;ng tin về số lần gh&eacute; thăm, bao gồm số trang qu&yacute; kh&aacute;ch xem, số li&ecirc;n kết (links) qu&yacute; kh&aacute;ch click v&agrave; những th&ocirc;ng tin kh&aacute;c li&ecirc;n quan đến việc kết nối đến &ldquo;Thần T&agrave;i 68&rdquo;. &ldquo;Thần T&agrave;i 68&rdquo; cũng thu thập c&aacute;c th&ocirc;ng tin m&agrave; tr&igrave;nh duyệt web (browser) qu&yacute; kh&aacute;ch sử dụng mỗi khi truy cập v&agrave;o &ldquo;Thần T&agrave;i 68&rdquo;, bao gồm: địa chỉ IP, loại browser, ng&ocirc;n ngữ sử dụng, thời gian v&agrave; những địa chỉ m&agrave; browser truy xuất đến.</p>

                <p>9.1.5. C&aacute;c chủ thể (Người hoặc Tổ chức) được ph&eacute;p tiếp cận, sử dụng c&aacute;c th&ocirc;ng tin c&aacute; nh&acirc;n đươc thu thập ở tr&ecirc;n được quy định chi tiết tại điều 9.2 v&agrave; 9.3 b&ecirc;n dưới.</p>

                <p>9.2. Sử dụng th&ocirc;ng tin c&aacute; nh&acirc;n:</p>

                <p>9.2.1. Chỉ duy nhất &ldquo;Thần T&agrave;i 68&rdquo; được quyền thu thập, tiếp cận v&agrave; sử dụng th&ocirc;ng tin c&aacute; nh&acirc;n qu&yacute; kh&aacute;ch với mục đ&iacute;ch ph&ugrave; hợp v&agrave; ho&agrave;n to&agrave;n tu&acirc;n thủ nội dung của &ldquo;Ch&iacute;nh s&aacute;ch bảo mật&rdquo; n&agrave;y.</p>

                <p>9.2.2. Khi cần thiết, &ldquo;Thần T&agrave;i 68&rdquo; c&oacute; thể sử dụng những th&ocirc;ng tin n&agrave;y để li&ecirc;n hệ trực tiếp với qu&yacute; kh&aacute;ch dưới c&aacute;c h&igrave;nh thức như email, điện thoại, tin nhắn. Qu&yacute; kh&aacute;ch&nbsp;cũng c&oacute; thể nhận được email cung cấp th&ocirc;ng tin sản phẩm, dịch vụ mới, th&ocirc;ng tin về c&aacute;c sự kiện sắp tới hoặc th&ocirc;ng tin tuyển dụng nếu qu&yacute; kh&aacute;ch đăng k&yacute; nhận email th&ocirc;ng b&aacute;o.</p>

                <p>9.3. Chia sẻ th&ocirc;ng tin c&aacute; nh&acirc;n:</p>

                <p>9.3.1. Ngoại trừ c&aacute;c trường hợp về &quot;Sử dụng th&ocirc;ng tin c&aacute; nh&acirc;n&quot; như đ&atilde; n&ecirc;u trong ch&iacute;nh s&aacute;ch n&agrave;y, &ldquo;Thần T&agrave;i 68&rdquo; cam kết sẽ kh&ocirc;ng tiết lộ th&ocirc;ng tin c&aacute; nh&acirc;n qu&yacute; kh&aacute;ch ra ngo&agrave;i.</p>

                <p>9.3.2. Trong một số trường hợp, &ldquo;Thần T&agrave;i 68&rdquo; c&oacute; thể thu&ecirc; một đơn vị độc lập để tiến h&agrave;nh c&aacute;c dự &aacute;n nghi&ecirc;n cứu thị trường v&agrave; khi đ&oacute; th&ocirc;ng tin của qu&yacute; kh&aacute;ch sẽ được cung cấp cho đơn vị n&agrave;y để tiến h&agrave;nh dự &aacute;n. B&ecirc;n thứ ba n&agrave;y sẽ bị r&agrave;ng buộc bởi một thỏa thuận về bảo mật m&agrave; theo đ&oacute; họ chỉ&nbsp;</p>

                <p>được ph&eacute;p sử dụng những th&ocirc;ng tin được cung cấp cho mục đ&iacute;ch ho&agrave;n th&agrave;nh dự &aacute;n.</p>

                <p>9.3.3. &ldquo;Thần T&agrave;i 68&rdquo; c&oacute; thể tiết lộ hoặc cung cấp th&ocirc;ng tin c&aacute; nh&acirc;n của qu&yacute; kh&aacute;ch trong c&aacute;c trường hợp thật sự cần thiết như sau:</p>

                <p>a. Khi c&oacute; y&ecirc;u cầu của c&aacute;c cơ quan ph&aacute;p luật;</p>

                <p>b. Trong trường hợp m&agrave; &ldquo;Thần T&agrave;i 68&rdquo; tin rằng điều đ&oacute; sẽ gi&uacute;p &ldquo;Mua Hộ V&eacute; Số&rdquo; bảo vệ quyền lợi ch&iacute;nh đ&aacute;ng của &ldquo;Thần T&agrave;i 68&rdquo; trước ph&aacute;p luật;</p>

                <p>c. T&igrave;nh huống khẩn cấp v&agrave; cần thiết để bảo vệ quyền an to&agrave;n c&aacute; nh&acirc;n của c&aacute;c th&agrave;nh vi&ecirc;n &ldquo;Thần T&agrave;i 68&rdquo;.</p>

                <p>9.4. Truy xuất th&ocirc;ng tin c&aacute; nh&acirc;n:&nbsp;</p>

                <p>Bất cứ thời điểm n&agrave;o qu&yacute; kh&aacute;ch cũng c&oacute; thể truy cập v&agrave; chỉnh sửa những th&ocirc;ng tin c&aacute; nh&acirc;n của m&igrave;nh theo c&aacute;c li&ecirc;n kết th&iacute;ch hợp m&agrave; &ldquo;Thần T&agrave;i 68&rdquo; cung cấp.</p>

                <p>9.5. Bảo mật th&ocirc;ng tin c&aacute; nh&acirc;n:</p>

                <p>9.5.1. Khi qu&yacute; kh&aacute;ch gửi th&ocirc;ng tin c&aacute; nh&acirc;n cho &ldquo;Thần T&agrave;i 68&rdquo;, qu&yacute; kh&aacute;ch đ&atilde; đồng &yacute; với c&aacute;c điều khoản m&agrave; &ldquo;Thần T&agrave;i 68&rdquo; đ&atilde; n&ecirc;u ở tr&ecirc;n, &ldquo;Thần T&agrave;i 68&rdquo; cam kết bảo mật th&ocirc;ng tin c&aacute; nh&acirc;n của qu&yacute; kh&aacute;ch bằng mọi c&aacute;ch thức c&oacute; thể. Hoạt động Thương mại điện tử của &ldquo;Thần T&agrave;i 68&rdquo;, bao gồm việc đảm bảo khả năng bảo mật th&ocirc;ng tin của Người d&ugrave;ng trong qu&aacute; tr&igrave;nh giao dịch đ&atilde; được chứng nhận bởi Bộ C&ocirc;ng Thương.</p>

                <p>9.5.2. &ldquo;Thần T&agrave;i 68&rdquo; cũng khuyến c&aacute;o qu&yacute; kh&aacute;ch n&ecirc;n tu&acirc;n thủ c&aacute;c nguy&ecirc;n tắc&nbsp;</p>

                <p>sau để c&oacute; thể bảo vệ th&ocirc;ng tin c&aacute; nh&acirc;n của m&igrave;nh, gồm:</p>

                <p>a. Kh&ocirc;ng tiết lộ T&ecirc;n sử dụng/T&ecirc;n đăng nhập v&agrave;/hoặc Mật khẩu với bất kỳ ai, viết hoặc sử dụng ở nơi m&agrave; mọi người c&oacute; thể nhận biết v&agrave; nh&igrave;n thấy được.</p>

                <p>b. Khi chọn mật khẩu qu&yacute; kh&aacute;ch kh&ocirc;ng n&ecirc;n chọn những th&ocirc;ng tin dễ x&aacute;c định như họ t&ecirc;n, ng&agrave;y sinh, số điện thoại hoặc một số k&yacute; tự, con số dễ nhận biết từ t&ecirc;n, ng&agrave;y sinh, số điện thoại của qu&yacute; kh&aacute;ch.</p>

                <p>c. Tho&aacute;t khỏi hệ thống v&agrave; tr&igrave;nh duyệt khi rời khỏi m&aacute;y t&iacute;nh, ngay cả trong thời gian ngắn. Nếu sử dụng m&aacute;y t&iacute;nh chung nhiều người, qu&yacute; kh&aacute;ch n&ecirc;n đăng xuất, hoặc tho&aacute;t hết tất cả cửa sổ website đang mở.</p>

                <p>d. Khi nghi ngờ mật khẩu đ&atilde; bị lộ, qu&yacute;&nbsp;</p>

                <p>kh&aacute;ch thay đổi mật khẩu ngay lập tức, trong trường hợp cần thiết qu&yacute; kh&aacute;ch lập tức th&ocirc;ng b&aacute;o v&agrave; đề nghị được hỗ trợ từ &ldquo;Thần T&agrave;i 68&rdquo;.</p>

                <p>e. Trong trường hợp c&aacute;c giao dịch, h&agrave;nh vi tr&aacute;i ph&aacute;p luật được tiến h&agrave;nh với t&agrave;i khoản hoặc th&ocirc;ng th&ocirc;ng tin c&aacute; nh&acirc;n của qu&yacute; kh&aacute;ch m&agrave; kh&ocirc;ng c&oacute; sự ủy quyền hoặc do sự sai s&oacute;t, v&ocirc; &yacute; hoặc cố &yacute; từ ph&iacute;a qu&yacute; kh&aacute;ch, &ldquo;Thần T&agrave;i 68&rdquo; hiểu rằng qu&yacute; kh&aacute;ch sẽ chịu to&agrave;n bộ tổn thất hoặc tr&aacute;ch nhiệm theo quy định ph&aacute;p luật khi c&aacute;c giao dịch đ&oacute; được thực hiện.</p>

                <p>9.6. Thời gian lưu trữ th&ocirc;ng tin:</p>

                <p>Dữ liệu c&aacute; nh&acirc;n của người d&ugrave;ng sẽ được lưu trữ cho đến khi c&oacute; y&ecirc;u cầu hủy bỏ hoặc tự người d&ugrave;ng đăng nhập v&agrave; thực hiện hủy bỏ. C&ograve;n lại trong mọi trường hợp th&ocirc;ng tin&nbsp;c&aacute; nh&acirc;n của người d&ugrave;ng sẽ được bảo mật tr&ecirc;n m&aacute;y chủ của &rdquo; Thần T&agrave;i 68&rdquo;.&nbsp;</p>

                <p>9.7. Thanh to&aacute;n an to&agrave;n:</p>

                <p>Mọi Người d&ugrave;ng tham gia giao dịch tại &ldquo;Thần T&agrave;i 68&rdquo; qua thẻ t&iacute;n dụng/thẻ ghi nợ/thẻ ATM nội địa đều được bảo mật th&ocirc;ng tin bằng m&atilde; h&oacute;a. B&ecirc;n cạnh đ&oacute;, khi thực hiện thanh to&aacute;n qua mạng, qu&yacute; kh&aacute;ch vui l&ograve;ng lưu &yacute; c&aacute;c chi tiết sau:</p>

                <p>9.7.1. Chỉ thanh to&aacute;n tr&ecirc;n website c&oacute; chứng chỉ an to&agrave;n, bảo mật hệ thống thẻ.</p>

                <p>9.7.2. Tuyệt đối kh&ocirc;ng cho người kh&aacute;c mượn thẻ t&iacute;n dụng hoặc t&agrave;i khoản của m&igrave;nh để thực hiện thanh to&aacute;n tr&ecirc;n website; trong trường hợp ph&aacute;t sinh giao dịch ngo&agrave;i &yacute; muốn, Người d&ugrave;ng vui l&ograve;ng th&ocirc;ng b&aacute;o ngay lập tức cho &ldquo;Thần T&agrave;i 68&rdquo; để &ldquo;Thần T&agrave;i 68&rdquo; c&oacute; thể hỗ trợ kịp thời.</p>

                <p>9.7.3. Kiểm tra t&agrave;i khoản ng&acirc;n h&agrave;ng của m&igrave;nh thường xuy&ecirc;n để đảm bảo tất cả giao dịch qua thẻ đều nằm trong tầm kiểm so&aacute;t.</p>

                <p>9.8. Sử dụng &ldquo;cookie&rdquo;:</p>

                <p>9.8.1. Cookie l&agrave; một file văn bản được đặt tr&ecirc;n đĩa cứng của qu&yacute; kh&aacute;ch bởi một m&aacute;y chủ của trang web. Cookie kh&ocirc;ng được d&ugrave;ng để chạy chương tr&igrave;nh hay đưa virus v&agrave;o m&aacute;y t&iacute;nh của qu&yacute; kh&aacute;ch. Cookie được chỉ định v&agrave;o m&aacute;y t&iacute;nh của qu&yacute; kh&aacute;ch v&agrave; chỉ c&oacute; thể được đọc bởi một m&aacute;y chủ trang web tr&ecirc;n miền được đưa ra cookie cho qu&yacute; kh&aacute;ch.</p>

                <p>9.8.2. &ldquo;Thần T&agrave;i 68&rdquo; d&ugrave;ng &quot;cookie&quot; để gi&uacute;p c&aacute; nh&acirc;n h&oacute;a v&agrave; n&acirc;ng cao tối đa hiệu quả sử dụng thời gian trực tuyến của qu&yacute;&nbsp;</p>

                <p>kh&aacute;ch khi truy cập &ldquo;Thần T&agrave;i 68&rdquo; m&agrave; kh&ocirc;ng cần đăng k&yacute; lại th&ocirc;ng tin sẵn c&oacute;.</p>

                <p>9.8.3. Qu&yacute; kh&aacute;ch c&oacute; thể chấp nhận hoặc từ chối d&ugrave;ng cookie. Hầu hết những browser tự động chấp nhận cookie, nhưng qu&yacute; kh&aacute;ch c&oacute; thể thay đổi những c&agrave;i đặt để từ chối tất cả những cookie nếu qu&yacute; kh&aacute;ch th&iacute;ch. Tuy nhi&ecirc;n, nếu qu&yacute; kh&aacute;ch chọn từ chối cookie, điều đ&oacute; c&oacute; thể g&acirc;y cản trở v&agrave; ảnh hưởng kh&ocirc;ng tốt đến một số dịch vụ v&agrave; t&iacute;nh năng phụ thuộc v&agrave;o cookie tại &ldquo;Thần T&agrave;i 68&rdquo;.</p>

                <p>9.9. Quy định về &ldquo;spam&rdquo;:</p>

                <p>9.9.1. Thư r&aacute;c (spam) l&agrave; c&aacute;c email giả mạo danh t&iacute;n &ldquo;Thần T&agrave;i 68&rdquo; gửi đi. &ldquo;Thần T&agrave;i 68&rdquo; khẳng định chỉ gửi email đến qu&yacute; kh&aacute;ch khi v&agrave; chỉ khi qu&yacute; kh&aacute;ch c&oacute; đăng k&yacute; hoặc sử dụng dịch vụ từ hệ thống của&nbsp;</p>

                <p>&ldquo;Thần T&agrave;i 68&rdquo;.</p>

                <p>9.9.2. &ldquo;Thần T&agrave;i 68&rdquo; cam kết kh&ocirc;ng b&aacute;n, thu&ecirc; lại hoặc cho thu&ecirc; email của qu&yacute; kh&aacute;ch từ b&ecirc;n thứ ba. Nếu qu&yacute; kh&aacute;ch v&ocirc; t&igrave;nh nhận được email kh&ocirc;ng theo y&ecirc;u cầu từ hệ thống &ldquo;Thần T&agrave;i 68&rdquo; do một nguy&ecirc;n nh&acirc;n ngo&agrave;i &yacute; muốn, xin vui l&ograve;ng nhấn v&agrave;o link từ chối nhận email k&egrave;m theo hoặc th&ocirc;ng b&aacute;o trực tiếp đến &ldquo;Thần T&agrave;i 68&rdquo;.</p>

                <p>9.10. Thay đổi về ch&iacute;nh s&aacute;ch:</p>

                <p>9.10.1. &ldquo;Thần T&agrave;i 68&rdquo; ho&agrave;n to&agrave;n c&oacute; thể thay đổi nội dung trong &ldquo;Ch&iacute;nh s&aacute;ch bảo mật&rdquo; m&agrave; kh&ocirc;ng cần phải th&ocirc;ng b&aacute;o trước, để ph&ugrave; hợp với c&aacute;c nhu cầu của &ldquo;Thần T&agrave;i 68&rdquo; cũng như nhu cầu được phản hồi từ Người d&ugrave;ng. Khi cập nhật nội dung ch&iacute;nh s&aacute;ch n&agrave;y, &ldquo;Thần T&agrave;i 68&rdquo; sẽ chỉnh sửa lại&nbsp;thời gian &ldquo;Cập nhật lần cuối&rdquo; b&ecirc;n dưới.</p>

                <p>9.10.2. Nội dung &ldquo;Ch&iacute;nh s&aacute;ch bảo mật&rdquo; n&agrave;y chỉ &aacute;p dụng tại &ldquo;Thần T&agrave;i 68&rdquo;, kh&ocirc;ng bao gồm hoặc li&ecirc;n quan đến c&aacute;c b&ecirc;n thứ ba đặt quảng c&aacute;o hay c&oacute; link tại &ldquo;Thần T&agrave;i 68&rdquo;. &ldquo;Thần T&agrave;i 68&rdquo; khuyến kh&iacute;ch qu&yacute; kh&aacute;ch đọc kỹ &quot;Ch&iacute;nh s&aacute;ch An to&agrave;n v&agrave; Bảo mật&quot; của c&aacute;c trang web của b&ecirc;n thứ ba trước khi cung cấp th&ocirc;ng tin c&aacute; nh&acirc;n cho c&aacute;c trang web đ&oacute;.</p>

                <p><strong>Điều 10. Cập nhật</strong></p>

                <p>Điều khoản n&agrave;y c&oacute; thể được cập nhật thường xuy&ecirc;n bởi C&ocirc;ng ty Cổ phần Thương Mại Vicon phi&ecirc;n bản cập nhật sẽ được &ldquo;Thần T&agrave;i 68&rdquo; c&ocirc;ng bố tại website c&ocirc;ng ty. Phi&ecirc;n bản cập nhật sẽ thay thế cho c&aacute;c quy định v&agrave; điều khoản trong thỏa thuận ban đầu. Người d&ugrave;ng c&oacute; thể truy cập v&agrave;o Ứng dụng &ldquo;Thần T&agrave;i 68&rdquo; hoặc v&agrave;o website của &ldquo;Thần T&agrave;i 68&rdquo; để xem nội dung chi tiết c&aacute;c phi&ecirc;n bản cập nhật.</p>

                <p><strong>Điều 11. Quyền sở hữu dịch vu</strong></p>

                <p>Dịch vụ n&agrave;y được ph&aacute;t triển v&agrave; sở hữu bởi C&ocirc;ng ty Cổ Phần Giải Tr&iacute; Số WeLuck, tất cả c&aacute;c quyền sở hữu tr&iacute; tuệ li&ecirc;n quan đến dịch vụ (bao gồm nhưng kh&ocirc;ng giới hạn m&atilde; nguồn, h&igrave;nh ảnh, dữ liệu, th&ocirc;ng tin, nội dung chứa đựng trong dịch vụ; c&aacute;c sửa đổi bổ sung, cập nhật của dịch vụ) v&agrave; c&aacute;c t&agrave;i liệu hướng dẫn li&ecirc;n quan (nếu c&oacute;) sẽ thuộc quyền sở hữu duy nhất bởi C&ocirc;ng ty Cổ Phần Giải Tr&iacute; Số WeLuck v&agrave; kh&ocirc;ng c&aacute; nh&acirc;n, tổ chức n&agrave;o được ph&eacute;p sao ch&eacute;p, t&aacute;i tạo, ph&acirc;n phối, hoặc h&igrave;nh thức kh&aacute;c x&acirc;m phạm tới quyền của chủ sở hữu nếu kh&ocirc;ng c&oacute; sự&nbsp;</p>

                <p>đồng &yacute; v&agrave; cho ph&eacute;p bằng văn bản của C&ocirc;ng ty Cổ Phần Giải Tr&iacute; Số WeLuck</p>

                <p><strong>Điều 12. Xử l&yacute; vi phạm</strong></p>

                <p>Trường hợp Người d&ugrave;ng vi phạm bất kỳ Quy định n&agrave;o trong Điều khoản n&agrave;y, C&ocirc;ng ty Cổ phần Giải Tr&iacute; Số WeLuck c&oacute; quyền ngay lập tức kh&oacute;a t&agrave;i khoản của Người d&ugrave;ng v&agrave;/hoặc x&oacute;a bỏ to&agrave;n bộ c&aacute;c th&ocirc;ng tin, nội dung vi phạm, đồng thời t&ugrave;y thuộc v&agrave;o t&iacute;nh chất, mức độ vi phạm Người d&ugrave;ng sẽ phải chịu tr&aacute;ch nhiệm trước cơ quan c&oacute; thẩm quyền, C&ocirc;ng ty Cổ phần Giải Tr&iacute; Số WeLuck v&agrave; b&ecirc;n thứ ba về mọi thiệt hại g&acirc;y ra bởi hoặc xuất ph&aacute;t từ h&agrave;nh vi vi phạm của Người d&ugrave;ng.</p>

                <p><strong>Li&ecirc;n lạc với &ldquo;Thần T&agrave;i 68&rdquo;</strong></p>

                <p>C&ocirc;ng ty Cổ Phần Giải Tr&iacute; Số WeLuck<br />
                Hotline: 0964 610 210 - Email: cskh@thantai68.net</p>
              `
            }
            else {
              this.content = content;
            }
            if (image_url) {
              this.srcImg = image_url;
            }
          })
          .catch(() => {
            this.$router.push({ name: 'notFound' });
          });
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.content {
  background-color: #fff;
  padding: 1rem;
  border-radius: 5px;
  margin-top: 3.125rem;
  margin-bottom: 3.125rem;
}

@media (max-width: map-get($grid-breakpoints, 'md')) {
  .content {
    background-color: #fff;
    padding: 1rem;
    border-radius: 5px;
    margin-top: 0;
    margin-bottom: 0;
  }
}
</style>
